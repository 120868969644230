<template>
  <div
    :style="[
      $vuetify.breakpoint.width <= 959
        ? { marginBottom: '471px' }
        : { marginBottom: '575px' },
    ]"
  >
    <div class="black-box-container">
      <LeftNavBar />
      <Sidebar />

      <!-- Project header - title and information -->
      <section>
        <v-layout row wrap align-center justify-center>
          <v-flex xs12>
            <v-card flat color="transparent">
              <v-card-title primary-title class="justify-center">
                <div>
                  <h3
                    :style="{
                      backgroundImage: `-webkit-linear-gradient(96deg, #f66035 4%, #ff3300)`,
                    }"
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'project-title-mobile'
                        : 'project-title',
                    ]"
                    class="mb-0"
                  >
                    Duda's new text editor
                  </h3>
                  <div
                    class="pb-2"
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'project-subtitle-extended-mobile'
                        : 'project-subtitle-extended',
                    ]"
                  >
                    Next generation text editor of the Duda website builder
                    platform
                  </div>
                </div>
              </v-card-title>
            </v-card>
          </v-flex>
        </v-layout>

        <v-layout
          :style="[isMobile ? { borderTop: '1px solid #262626' } : '']"
          class="row wrap"
          :class="[
            $vuetify.breakpoint.width >= 1426
              ? 'justify-center'
              : 'justify-start',
          ]"
        >
          <v-flex
            class="px-3"
            :class="[
              isMobile
                ? 'project-info-box-mobile xs6'
                : 'project-info-box shrink',
            ]"
          >
            <v-card flat color="transparent" height="100%">
              <v-card-title>
                <div :class="[isMobile ? 'mx-3' : '']">
                  <span
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'grey-title-mobile'
                        : 'grey-title',
                    ]"
                    class="d-block mb-2"
                    >Type</span
                  >
                  <span
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'headline-mobile'
                        : 'headline-desktop',
                    ]"
                    >Feature</span
                  >
                </div>
              </v-card-title>
            </v-card>
          </v-flex>

          <v-flex
            class="px-3"
            :class="[
              isMobile
                ? 'project-info-box-mobile xs6'
                : 'project-info-box shrink',
            ]"
          >
            <v-card flat color="transparent" height="100%">
              <v-card-title>
                <div :class="[isMobile ? 'mx-3' : '']">
                  <span
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'grey-title-mobile'
                        : 'grey-title',
                    ]"
                    class="d-block mb-2"
                    >Data</span
                  >
                  <span
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'headline-mobile'
                        : 'headline-desktop',
                    ]"
                    >February, 2020</span
                  >
                </div>
              </v-card-title>
            </v-card>
          </v-flex>

          <v-flex
            class="px-3"
            :class="[
              isMobile
                ? 'project-info-box-mobile xs6'
                : 'project-info-box shrink',
            ]"
          >
            <v-card flat color="transparent" height="100%">
              <v-card-title>
                <div :class="[isMobile ? 'mx-3' : '']">
                  <span
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'grey-title-mobile'
                        : 'grey-title',
                    ]"
                    class="d-block mb-2"
                    >Role</span
                  >
                  <span
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'headline-mobile'
                        : 'headline-desktop',
                    ]"
                    >Product Designer</span
                  >
                </div>
              </v-card-title>
            </v-card>
          </v-flex>

          <v-flex
            class="px-3"
            :class="[
              isMobile
                ? 'project-info-box-mobile xs6'
                : 'project-info-box shrink',
            ]"
          >
            <v-card flat color="transparent" height="100%">
              <v-card-title>
                <div :class="[isMobile ? 'mx-3' : '']">
                  <span
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'grey-title-mobile'
                        : 'grey-title',
                    ]"
                    class="d-block mb-2"
                    >Company</span
                  >
                  <span
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'headline-mobile'
                        : 'headline-desktop',
                    ]"
                    >Duda</span
                  >
                </div>
              </v-card-title>
            </v-card>
          </v-flex>
        </v-layout>
        <v-spacer class="hidden-sm-and-down pb-5"></v-spacer>
      </section>

      <!-- Project - two images section -->
      <!-- <section id="two-images">
        <v-card
          flat
          color="transparent"
          :style="{
            background: `linear-gradient(18deg, #f66035 3%, #ff3300 100%)`,
          }"
        >
          <v-layout
            row
            align-center
            justify-center
            fill-height
            class="img-container"
          >
            <v-img
              :class="[
                $vuetify.breakpoint.width <= 600
                  ? 'left-img-mobile'
                  : 'left-img',
              ]"
              contain
              :src="headerImage"
            ></v-img>
          </v-layout>
        </v-card>
      </section> -->

      <section id="two-images">
        <v-card
          flat
          color="transparent"
          :style="{
            background: `linear-gradient(18deg, #f66035 3%, #ff3300 100%)`,
          }"
        >
          <v-layout
            row
            align-center
            justify-center
            fill-height
            class="img-container"
          >
            <v-img
              :class="[
                $vuetify.breakpoint.width <= 600
                  ? 'left-img-mobile'
                  : 'left-img',
              ]"
              contain
              :src="headerImage"
            ></v-img>
          </v-layout>
        </v-card>
      </section>

      <!-- Project info section - About and challenge -->
      <section id="project-info">
        <v-card flat color="transparent">
          <v-layout row wrap align-start justify-center style="margin-top: 5em">
            <v-flex xs11 md6 lg5>
              <v-card-title class="pb-2">
                <span
                  :class="[
                    $vuetify.breakpoint.width <= 1060
                      ? 'grey-title-mobile'
                      : 'grey-title',
                  ]"
                  >About Duda</span
                >
              </v-card-title>
              <v-card-text class="pt-1">
                <span class="project-top-text"
                  >Duda is the leading web design platform for companies that
                  offer web design services to small businesses. We serve all
                  types of customers, from freelance web professionals and
                  digital agencies, all the way up to the largest hosting
                  companies and online publishers in the world.Loaded with
                  powerful team collaboration and client management tools, the
                  Duda platform enables users to build feature-rich, responsive
                  websites at scale. Every Duda website is automatically
                  optimised for lightning-fast loading and great out-of-the-box
                  SEO. Currently, over 14 million sites have been built on the
                  Duda platform.</span
                >
              </v-card-text>
            </v-flex>
            <v-flex xs11 md6 lg5>
              <v-card-title class="pb-2">
                <span
                  :class="[
                    $vuetify.breakpoint.width <= 1060
                      ? 'grey-title-mobile'
                      : 'grey-title',
                  ]"
                  >The Challenge</span
                >
              </v-card-title>
              <v-card-text class="pt-1">
                <span class="project-top-text"
                  >The old text editor was based on old framework that was full
                  of bugs, and any other development brought many hours of
                  development with it.In addition, the old text editor allowed
                  invalid HTML markup to be used, and it’s really bad for SEO.
                  We wanted to bring the users a faster, smoother and
                  full-of-new-features new text editor.</span
                >
              </v-card-text>
            </v-flex>
          </v-layout>
        </v-card>
      </section>

      <v-spacer></v-spacer>

      <!-- Why I did what I did section -->
      <section id="why" class="container">
        <v-card
          class="why-container py-2"
          :class="[isMobile ? 'py-2' : 'py-2 pl-4 pr-5']"
        >
          <v-card-title>
            <div class="why-title">My role in the project</div>
          </v-card-title>
          <v-card-text class="why-text">
            I was the Product Designer & Product Manager of this project. It was
            a project of my squad, called “Fast & Beautiful”. Our job was to
            give Duda’s users tools to create a beautiful website, quickly.
            <br />
            <br />
            I’ve collaborated with 3 front end developers, 1 back end developer,
            2 QAs and a Tech leader.
            <br />
            <br />
            My role was to come up with UX solutions for issues we’ve had with
            the old text editor, and prioritizing bugs & features. I’ve
            conducted researches, MVPs, specs, wireframes, designs,
            presentations & user surveys.
          </v-card-text>
        </v-card>
      </section>

      <v-spacer></v-spacer>

      <!-- Video section -->
      <section class="container">
        <v-card class="d-flex justify-center black-background">
          <v-flex xs12 lg10>
            <v-card class="pt-2 px-2 pb-1 mt-2 black-background">
              <video
                loop
                autoplay
                muted
                playsinline
                src="@assets/images/projects/Dudas-new-text-editor/text-editor-demo.mp4"
              ></video>
            </v-card>
          </v-flex>
        </v-card>
      </section>

      <!-- Current animations section -->

      <section class="container">
        <v-layout class="d-flex justify-center">
          <v-flex xs12 lg9>
            <p class="Current-animations">Why did we make a new text editor?</p>
            <p class="why-text">
              <span class="why-text-bold"
                >Current text editor is built on old framework</span
              >, so every development needed takes 5x-10x the time it would take
              on an updated framework.
              <br />
              <br />
              <span class="why-text-bold"
                >Users complaints about its lack of advanced features.</span
              >They mean other than Bold / Italic / Underline - they need some
              advanced visuali capabilities (it related to the old framework
              issue). We also have an “inline binding” features, which means you
              place content on the content tab, and then you “inject” this
              content to anywhere on your website, and you can design it
              differently on any place you’ve injected it to.
              <br />
              <br />
              <span class="why-text-bold"
                >On the old framework you couldn’t see the actual text, but a
                messy code.</span
              >
              <br />
              <br />
              We wanted to replace this text editor with a new one that will
              solve these problems.
            </p>
          </v-flex>
        </v-layout>

        <v-layout class="d-flex justify-center">
          <v-flex xs12 lg9>
            <v-divider class="divider-style mt-5 mb-5"></v-divider>
          </v-flex>
        </v-layout>

        <v-layout class="d-flex justify-center">
          <v-flex xs12 lg9>
            <p class="Current-animations">Solution 1 - Forced update</p>
            <p class="why-text-bold">
              he text editor is a highly used feature in the Duda website
              builder (150K uses per day).
            </p>
            <p class="why-text">
              ‍ANY change to the experience will bring many unhappy users, that
              are saying “who moved my cheese?!”. I’ve expected that to happen
              and I wanted to pay close attention to feedback and to respond to
              it quickly and appropriately.
            </p>

            <p class="why-text">
              So we’ve planned to auto update once clicking on a text widget
              (=“forced update”), so basically you can’t access anymore the old
              text editor, and every time you click on a text widget - it’ll
              always display the new text editor.
            </p>
            <p class="why-text">
              The update takes place behind the scenes. The update process was
              the weak point of the project since there were many edge cases to
              handle on migration from the old framework to the new one.
            </p>
          </v-flex>
        </v-layout>
        <!-- Video section -->
        <section class="container">
          <v-card class="d-flex justify-center black-background">
            <v-flex xs12 lg8>
              <v-card class="pt-2 px-2 pb-1 mt-2 black-background">
                <video
                  loop
                  autoplay
                  playsinline
                  muted
                  src="@assets/images/projects/Dudas-new-text-editor/New-text-editor-MOD-transcode.mp4"
                ></video>
              </v-card>
            </v-flex>
          </v-card>
        </section>
      </section>

      <!-- Solution 2 - Migration on demand -->
      <section class="container bg-white">
        <v-layout class="d-flex justify-center">
          <v-flex xs12 lg9>
            <p class="Current-animations">Solution 2 - Migration on demand</p>
            <p class="why-text">
              <span class="why-text-bold"
                >The users responded to our decision to make the forced update -
                they didn’t like it, at all.</span
              >
              <br />
              They didn’t like it because on the update some values got lost /
              changed, examples:<br />
              1. Bold got removed<br />
              2. Text color was changed to the global color (there’s a global
              pallete for P&H1-6)<br />
              3. Font was changed<br />
              4. Italic was added<br />
              5. and so on…
            </p>
            <p class="why-text">
              <span class="why-text-bold">We assumed that</span>
              <br />
              1. The migration is pretty good<br />
              2. The users will say “oh here’s a bug, but I understand because
              there was an update progress”
            </p>
            <p class="why-text">
              <span class="why-text-bold">But the truth is</span>
              <br />
              1. The migration had many issues and I though should be considered
              as a project of its own. We underestimated the migration feature.
              I decided we need to dedicate a roadmap item for it since it’s a
              core feature inside another core feature.<br />
              2. The users don’t know there was an update behind the scenes, and
              they don’t care even if they knew.
            </p>
            <p class="why-text">
              I wanted to shift to another method called “Migration On Demand” -
              it means the user need to click on an “update” button on the old
              text editor in order to get the new one. Not only this - we let
              the user see the updated text, and the user need to approve the
              update in order to keep the new text editor for the specific text
              widget.
            </p>
            <p class="why-text">
              With the new method, we hoped users will get any “bug” with more
              understanding of what happend, and therefor reacting better to it.
            </p>
            <p class="why-text">
              <span class="why-text-bold">The trade off</span> was that users
              will have to manual update their text widgets 1 by 1, but we know
              that if this solution will be welcomed by the users - we’ll create
              a tool that will auto update all text widgets on every site.
            </p>
            <div>
              <p class="why-text-bold bg-brown">We went with this solution.</p>
            </div>
          </v-flex>
        </v-layout>
      </section>

      <!-- Solution 3 - Migration on demand -->
      <!-- Video 1 -->
      <section class="container">
        <v-layout class="d-flex justify-center">
          <v-flex xs12 lg7>
            <p class="Current-animations">
              The benefits of the new text editor
            </p>
            <p class="why-text">
              <span class="why-text-bold">New text styles</span>
              <br />
              Our users will now be able to design your text in much more
              creative ways.<br />
              1. Custom line height <br />
              2. Letter spacing <br />
              3. Text shadow <br />
              4. Strikethrough
            </p>
          </v-flex>
        </v-layout>
      </section>
      <!-- Video section -->
      <section class="container pt-0">
        <v-card class="d-flex justify-center black-background">
          <v-flex xs12 lg7>
            <v-card class="pt-2 px-2 pb-1 mt-2 black-background">
              <video
                loop
                autoplay
                playsinline
                muted
                src="@assets/images/projects/Dudas-new-text-editor/New-text-editor-MOD-transcode.mp4"
              ></video>
            </v-card>
          </v-flex>
        </v-card>
      </section>
      <!-- Video 2 -->
      <section class="container">
        <v-layout class="d-flex justify-center">
          <v-flex xs12 lg7>
            <p class="why-text">
              <span class="why-text-bold">Multiple heading types</span>
              <br />
              Host H and P heading types in the same text widget
            </p>
          </v-flex>
        </v-layout>
      </section>
      <!-- Video section -->
      <section class="container pt-0">
        <v-card class="d-flex justify-center black-background">
          <v-flex xs12 lg7>
            <v-card class="pt-2 px-2 pb-1 mt-2 black-background">
              <video
                loop
                autoplay
                playsinline
                muted
                src="@assets/images/projects/Dudas-new-text-editor/multiple headings-transcode.mp4"
              ></video>
            </v-card>
          </v-flex>
        </v-card>
      </section>
      <!-- Video 3 -->
      <section class="container">
        <v-layout class="d-flex justify-center">
          <v-flex xs12 lg7>
            <p class="why-text">
              <span class="why-text-bold">New UI for inline binding</span>
              <br />
              Now you can see the value of the content and not some messy code.
            </p>
          </v-flex>
        </v-layout>
      </section>
      <!-- Video section -->
      <section class="container pt-0">
        <v-card class="d-flex justify-center black-background">
          <v-flex xs12 lg7>
            <v-card class="pt-2 px-2 pb-1 mt-2 black-background">
              <video
                loop
                autoplay
                playsinline
                muted
                src="@assets/images/projects/Dudas-new-text-editor/multiple headings-transcode.mp4"
              ></video>
            </v-card>
          </v-flex>
        </v-card>
      </section>
      <!-- Video 4 -->
      <section class="container">
        <v-layout class="d-flex justify-center">
          <v-flex xs12 lg7>
            <p class="why-text">
              <span class="why-text-bold">Better copy paste mechanism</span>
              <br />
              ‍We now match the global style of the website on paste &keeping
              any inline bold / italic / underline.
            </p>
          </v-flex>
        </v-layout>
      </section>
      <!-- Video section -->
      <section class="container pt-0">
        <v-card class="d-flex justify-center black-background">
          <v-flex xs12 lg7>
            <v-card class="pt-2 px-2 pb-1 mt-2 black-background">
              <video
                loop
                autoplay
                playsinline
                muted
                src="@assets/images/projects/Dudas-new-text-editor/Benefits-copy paste-transcode.mp4"
              ></video>
            </v-card>
          </v-flex>
        </v-card>
      </section>
      <!-- Video 5 -->
      <section class="container">
        <v-layout class="d-flex justify-center">
          <v-flex xs12 lg7>
            <p class="why-text">
              <span class="why-text-bold">Bigger font sizes</span>
              <br />
              ‍‍We now support up to 500px font size in order to enable you
              match some modern web designs that uses big typography as a part
              of the visual language of the site.
            </p>
          </v-flex>
        </v-layout>
      </section>
      <!-- Video section -->
      <section class="container pt-0">
        <v-card class="d-flex justify-center black-background">
          <v-flex xs12 lg7>
            <v-card class="pt-2 px-2 pb-1 mt-2 black-background">
              <video
                loop
                autoplay
                playsinline
                muted
                src="@assets/images/projects/Dudas-new-text-editor/Benefits-big-font-size-transcode.mp4"
              ></video>
            </v-card>
          </v-flex>
        </v-card>
      </section>
      <section class="container">
        <v-layout class="d-flex justify-center">
          <v-flex xs12 lg7>
            <p class="why-text">
              <span class="why-text-bold">Better SEO</span>
              <br />
              ‍‍The new text editor enforces the HTML5 standard and promotes SEO
              best practices.
            </p>
          </v-flex>
        </v-layout>
      </section>

      <div class="section-grey-background">
        <section class="container">
          <v-layout class="pt-0 pb-3">
            <v-flex>
              <v-img
                class="mx-auto"
                max-width="875px"
                contain
                :src="oldNnew"
              ></v-img>
            </v-flex>
          </v-layout>
        </section>
      </div>

      <!-- Harvesting the fruits - new text styles -->

      <section class="container pt-5">
        <v-layout class="d-flex justify-center">
          <v-flex xs12 lg9>
            <p class="Current-animations">
              Harvesting the fruits - new text styles
            </p>
            <p class="why-text">
              Main reason for making a new text editor with a new framework is
              to be able to develop new features on it faster than before.
              <br />
              <br />
              We wanted to make new features for the new text editor but we
              faced<span class="why-text-bold"> 2 problems:</span><br />
              1. There are a lot of cool text styles and we don’t know what our
              users prefer<br />
              2. Where are we going to put all these new features?! The toolbar
              is wide already.
              <br />
              <br />
              <span class="why-text-bold">The solutions:</span><br />
              1. We made a survey in our facebook group (which is for resellers)
              and the result came really quick, the people have spoken.<br />
              2. We’ve added an icon for “more” options
            </p>
          </v-flex>
        </v-layout>
        <v-layout class="pt-0 pb-3">
          <v-flex>
            <v-img
              class="mx-auto"
              max-height="586px"
              contain
              :src="img10"
            ></v-img>
          </v-flex>
        </v-layout>
        <v-layout class="d-flex justify-center">
          <v-flex xs12 lg9>
            <p class="why-text-bold">
              We then went to develop these features (by this order):
            </p>
            <p class="why-text">
              1. Custom line height<br />
              2. Letter spacing<br />
              3. Text shadow<br />
              4. Strikethrough<br />
              We starte
              <br />
              <br />
              We started tracking the usage and it was just excellent!<br />
              Development was really fast (1 week of dev + qa per feature, total
              of 4 weeks).
            </p>
          </v-flex>
        </v-layout>
        <v-layout class="pt-0 pb-3">
          <v-flex>
            <v-img
              class="mx-auto"
              max-width="1043px"
              contain
              :src="img2"
            ></v-img>
          </v-flex>
        </v-layout>
      </section>

      <div class="section-grey-background">
        <section class="container">
          <v-layout class="d-flex justify-center">
            <v-flex xs12 lg9>
              <p class="Current-animations">Next steps</p>
              <p class="why-text">
                The main feedback about the new text styles was - “we want
                more!”, another main feedback was “we want them in the global
                design” - so you could implement those styles for every
                H1-H6/Paragraph style on your website.
              </p>
              <p class="why-text">
                As for the update of the new text update - we developed a tool
                that will allow user to input a site ID and the tool will update
                EVERY text widget on that website, so the user won’t need to
                update every text widget manually.
              </p>
            </v-flex>
          </v-layout>
          <v-layout class="pt-5 pb-3">
            <v-flex>
              <v-img
                class="mx-auto"
                max-width="473px"
                contain
                :src="img3"
              ></v-img>
            </v-flex>
          </v-layout>
        </section>
      </div>

      <section class="container pt-5">
        <v-layout class="d-flex justify-center">
          <v-flex xs12 lg9>
            <p class="Current-animations">Trade - offs</p>
            <v-card
              class="why-container-1 py-2 mb-3"
              :class="[isMobile ? 'py-2' : 'py-2 pl-4 pr-5']"
            >
              <v-card-text class="why-text">
                Duda's mission is to allow users to create beautiful websites
                fast and easy. From that mission statement we have many
                constraints and limitations we need to take into considerations
                when releasing a feature.
              </v-card-text>
            </v-card>
            <p class="why-text">
              <span class="why-text-bold"
                >When pasting, always getting the destination style</span
              ><br />
              Pro - Sticking to global design<br />
              Con - When copy pasting from the same text widget, all styling is
              gone<br />
              Solution - We’ll have a toggle for pasting behaviour
            </p>
            <p class="why-text">
              <span class="why-text-bold">Migration on demand</span><br />
              Pro - User has more empathy for some bugs<br />
              Con - User needs to update each text widget 1 by 1<br />
              Solution - We’ll develop a tool that will auto-update a whole site
              on 1 click
            </p>
          </v-flex>
        </v-layout>
      </section>

      <!-- Next project section -->
      <section id="next-project">
        <v-container>
          <v-layout row wrap justify-center align-center>
            <v-flex xs12 md5 lg5 xl5>
              <v-card flat class="transparent">
                <v-card-text class="py-0 my-0">
                  <div class="next-project d-block">Next project</div>
                </v-card-text>
                <v-card-title>
                  <h3
                    :style="{
                      backgroundImage: `-webkit-linear-gradient(287deg, #1f66d7 100%, #7275ff 3%)`,
                    }"
                    class="next-project-title d-block mt-0"
                    :class="[
                      $vuetify.breakpoint.width <= 1060
                        ? 'project-title-mobile-next'
                        : 'project-title',
                    ]"
                  >
                    WhileFly
                  </h3>
                </v-card-title>
                <v-card-text class="py-0 my-0">
                  <span class="next-project-category">Startup</span>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs8 md5 lg7 xl5 offset-xs4 offset-md0>
              <v-card flat class="transparent">
                <v-card-text
                  class="next-project-subtitle project-subtitle-extended project-subtitle text-xs-right"
                >
                  <div
                    :style="[
                      isMobile ? { fontSize: '21px' } : { fontSize: '28px' },
                    ]"
                  >
                    Whole new experience in the sky
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex
              xs10
              md10
              lg12
              xl10
              class="mt-5"
              @mouseover="mouseHandler"
              @mouseleave="mouseHandler"
            >
              <router-link to="/projects/WhileFly">
                <div
                  :style="[
                    isMobile
                      ? {
                          position: 'relative',
                          marginBottom: '40px',
                        }
                      : { marginBottom: '40px', overflow: 'hidden' },
                  ]"
                >
                  <v-card
                    flat
                    :style="{
                      background: `linear-gradient(284deg, #1f66d7 100%, #7275ff 4%`,
                    }"
                    style="paddingtop: 40px"
                  >
                    <v-img
                      contain
                      :src="nextProject"
                      class="mx-5 my-0 next-project-img"
                      :style="[
                        isMobile
                          ? ''
                          : { paddingLeft: '150px', paddingRight: '150px' },
                      ]"
                    ></v-img>
                  </v-card>
                  <span
                    v-if="isMobile"
                    class="big-btn-arrow"
                    :style="{
                      background: `url(@assets/images/projects/arrow.png) center center, linear-gradient(284deg, #1f66d7 100%, #7275ff 4%`,
                    }"
                  >
                    <v-img
                      class="arrow"
                      :src="require(`@assets/images/projects/arrow.png`)"
                    ></v-img>
                  </span>
                </div>
              </router-link>
            </v-flex>
          </v-layout>
        </v-container>
      </section>
    </div>

    <Footer />
  </div>
</template>

<script>
import Sidebar from "@components/_sidebarNav.vue";
import LeftNavBar from "@components/_leftSideNav.vue";
import Footer from "@components/_footer.vue";

export default {
  data() {
    return {
      isMobile: false,
      headerImage: require("@assets/images/projects/Dudas-new-text-editor/text-editor-hero.svg"),
      oldNnew: require("@assets/images/projects/Dudas-new-text-editor/old-vs-new.png"),
      img10: require("@assets/images/projects/Dudas-new-text-editor/text-editor-10.png"),
      img2: require("@assets/images/projects/Dudas-new-text-editor/text-editor-2.png"),
      img3: require("@assets/images/projects/Dudas-new-text-editor/text-editor-3.png"),
      nextProject: require("@assets/images/projects/WhileFly/next-project.png"),
    };
  },
  components: {
    Sidebar,
    LeftNavBar,
    Footer,
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.getWindowWidth);
      window.addEventListener("scroll", this.scrollHandler);

      //Init
      this.getWindowWidth();
      this.setProjectShowcase();

      // hack to delay "component visibility change" on render of all components
      setTimeout(() => {
        this.hasFinishedRendering = true;
      }, 500);
    });
  },
  methods: {
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
      if (this.windowWidth <= 959) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    mouseHandler(e) {
      // Next project section animation
      let nextProjectSubtitle = document.querySelector(
        ".next-project-subtitle"
      );
      let nextProjectImg = document.querySelector(".next-project-img");

      if (!this.isMobile) {
        if (e.type === "mouseover") {
          nextProjectSubtitle.style.opacity = 1;
          nextProjectImg.style.transform = "scale(1)";
        } else {
          nextProjectSubtitle.style.opacity = 0.7;
          nextProjectImg.style.transform = "scale(1.05)";
        }
      }
    },
  },
};
</script>


<style scoped lang="scss">
.section-grey-background {
  width: 100%;
  background-color: rgba(216, 216, 216, 0.13);
  padding: 50px 0px;
}
.bg-brown {
  background-color: rgba(249, 80, 34, 0.3);
  text-align: center;
  max-width: 285px;
}
.divider-style {
  border: solid 1px #979797bb;
}
.ggrey-background {
  background-color: brown;
}
video {
  // margin: 3rem auto;
  cursor: pointer;
  width: 100%;

  // max-height: 400px;
}
.why-text-bold {
  font-family: Futura-bold;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.5px;
  color: #ffffff;
}
.Current-animations {
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.32);
  font-family: Futura;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.86px;
  color: #ffffff;
}
.black-background {
  background-color: #000 !important;
}

.max-width-img {
  max-width: 1162px;
}
#single-project-desktop {
  position: relative;
  z-index: 2;
  margin-bottom: 400px;
}
.project-top-text {
  font-family: Futura-pt;
  font-size: 24px !important;
  font-weight: 300 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: -0.5px;
  color: #ffffff;
}
/* Project Header section */
.project-title {
  font-family: "futura-pt", sans-serif;
  font-size: 60px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1.44px;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.project-title-mobile {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.96px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.project-title-mobile-next {
  font-size: 40px;
  font-weight: bold;
  text-align: left;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.96px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.project-subtitle-extended {
  font-size: 30px;
  font-weight: normal;
  font-style: oblique;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.72px;
  text-align: center;
}

.project-subtitle-extended-mobile {
  font-size: 22px;
  font-weight: normal;
  font-style: oblique;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  text-align: center;
}

.project-third-extended {
  font-family: "futura-pt", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.38px;
  text-align: center;
  color: #727272;
}

.project-info-box {
  border-right: 1px solid #262626;
}

.project-info-box:last-child {
  border-right: 0px;
}

.project-info-box-mobile {
  border-bottom: 1px solid #262626;
  border-right: 1px solid #262626;
}

.project-info-box-mobile:nth-child(even) {
  border-right: 0px;
}

.project-info-box-mobile:nth-last-child(2) {
  border-bottom: 1px solid #262626;
}

.grey-title {
  font-family: Futura-pt;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.43px;
  color: #727272;
}

.grey-title-big {
  min-width: 260px !important;
}

.grey-title-mobile {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.34px;
  color: #727272;
}

.grey-title-mobile-big {
  min-width: 265px;
}

@media only screen and (min-width: 1375px) and (max-width: 1425px) {
  .layout.row.wrap.justify-start {
    margin: 0 10em;
  }
}

@media only screen and (min-width: 1234px) and (max-width: 1374px) {
  .layout.row.wrap.justify-start {
    margin: 0 5em;
  }
}

@media only screen and (min-width: 1074px) and (max-width: 1233px) {
  .layout.row.wrap.justify-start {
    margin: 0 10em;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1073px) {
  .layout.row.wrap.justify-start {
    margin: 0 5em;
  }
}

.headline-desktop {
  font-family: "Futura", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.58px;
  color: #ffffff;
}

.headline-mobile {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.43px;
  color: #ffffff;
}

@media (max-width: 750px) {
  .project-title-mobile {
    margin-top: 1.5em;
  }
}

@media (max-width: 600px) {
  .bg-brown {
    background-color: rgba(249, 80, 34, 0.3);
    width: 90%;
    text-align: center;
  }
}

/* Two phones section */
.layout.img-container {
  padding: 7.5em 0 3.5em 0;
  width: 50%;
  margin: 0 auto;
  position: relative;
  min-height: 480px;
}
@media (max-width: 625px) {
  .layout.img-container {
    min-height: 550px;
  }
}
@media (min-width: 625px) {
  .layout.img-container {
    min-height: 550px;
  }
}

@media (min-width: 725px) {
  .layout.img-container {
    min-height: 650px;
  }
}

@media (min-width: 825px) {
  .layout.img-container {
    min-height: 750px;
  }
}

@media (min-width: 925px) {
  .layout.img-container {
    min-height: 955px;
  }
}

.left-img {
  position: absolute;
  max-width: 1000px;
  top: 10%;
  left: 0;
  right: -25%;
  bottom: 20%;
  -webkit-transform: translateX(-10%);
  transform: translateX(-10%);
}

.left-img-mobile {
  position: absolute;
  top: 15%;
  left: -225%;
  right: 0;
  bottom: 10%;
  -webkit-transform: translateX(15%);
  transform: translateX(15%);
}

.right-img {
  position: absolute;
  top: 15%;
  left: 0%;
  right: -10%;
  bottom: 30%;
  -webkit-transform: translateX(25%);
  transform: translateX(25%) translateY(30%);
}

.right-img-mobile {
  position: absolute;
  top: 15%;
  left: 0;
  right: -25%;
  bottom: 15%;
  -webkit-transform: translateX(25%);
  transform: translateX(25%) translateY(15%);
}

.additional-img {
  position: absolute;
  z-index: 1 !important;
  max-width: 1335px;
  top: 10px;
  left: 0;
  right: 0;
  bottom: -102%;
  margin: 0 -6em;
}

.v-card__text > span {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.43px;
}

/* Project mockup section */

.front-text {
  transform: translateY(400px);
  transition: 0.25s;
}

.spacer {
  height: 5em;
}

img.v-parallax__image {
  width: 140%;
}

/* Project showcase section - desktop images */
.showcase-img {
  transition: all 1.5s ease-in-out;
  transform: translateY(350px);
  opacity: 0;

  &.is-showing {
    opacity: 1;
    transform: translateY(0);
  }
}

.showcase-img-2 {
  transition: all 1.5s ease-in-out;
  transform: translateY(350px);
  opacity: 0;

  &.is-showing {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (min-width: 1675px) {
  section#project-showcase-desktop.container {
    max-width: 1440px;
  }
}

/* Why I did what I did section */

.why-img {
  position: absolute;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  max-width: 210px;
}

.why-img-desktop {
  width: 20%;
  transform: translate(0%, -200%);
}

.why-img-mobile {
  width: 50%;
  transform: translate(50%, -150%);
}

.why-title {
  font-family: Futura-pt;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.62px;
  color: #727272;
}

.why-container {
  position: relative;
  border-radius: 10px;
  border: solid 1px #292929;
  background: #0b0b0b !important;
  margin: 0 auto;
  max-width: 1100px;
}

.why-container-1 {
  position: relative;
  border-radius: 10px;
  border: solid 1px #292929;
  background: #0b0b0b !important;
  // margin: 0 auto;
  max-width: 1100px;
}

.why-text {
  font-family: Futura-pt;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.5px;
  color: #ffffff;
}

/* Client testimony section */

blockquote.testimony-mobile {
  margin-top: -10%;
}
.blockquote.testimony-desktop {
  color: #fff;
  line-height: 1.49;
  z-index: 3;
  font-family: FuturaCondDemi;
  font-weight: normal;
  font-style: oblique;
  letter-spacing: normal;
  font-stretch: normal;
}
@media (min-width: 2200px) {
  .image-testimony-computer {
    width: 115% !important;
  }
}

/* Mobile showcase section  */

#project-showcase-mobile {
  background: transparent;
}

.mobile-showcase-title {
  width: 100%;
  opacity: 0.12;
  font-size: 240px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -5.76px;
  text-align: center;
}

.show-case-mobile-img {
  margin-top: -14%;
  margin-right: -2%;
  max-height: 1037px;
}

/* next project section */

.next-project {
  opacity: 0.48;
  font-family: Futura-pt;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.75px;
  color: #ffffff;
}

.next-project-title {
  font-size: 52px !important;
}

.next-project-category {
  border: solid 0.5px;
  padding: 8px 11px;
  border-radius: 15.5px;
  border-color: darkslategrey;
  font-size: 13px;
}

.next-project-subtitle {
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
}

.next-project-img {
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
  max-height: 301px;
}

.big-btn-arrow {
  width: 64px;
  height: 64px;
  border: solid 6px #0b0b0b;
  border-radius: 50% !important;
  box-sizing: content-box;
  position: absolute;
  right: -10px;
  bottom: -30px;
}

.arrow {
  width: 12px;
  height: 21px;
  margin: 20px auto;
}
</style>